<template>
	 <div class="h-full flex flex-col overflow-hidden">
		
        <div 
            v-if="loading == 'fetching'"
            class="h-full flex items-center justify-center">
            
            <div class="spinner"></div>
        </div>
		<div 
	 		v-else-if="transactions && transactions.length"
			class="overflow-auto flex-grow">
			
			<div 
				v-if="campaignTotal"
				class="flex flex-col text-center space-y-6 bg-beige-dark p-6">
				
				<h2>Kampanjeresultat totalt</h2>
				
				
				<div 
					v-if="campaign.type == 'products'"
					class="w-48 rounded-full relative mx-auto">
					
					<svg viewBox="0 0 36 36">
						<path 
							d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" 
							fill=" bg-gray-darkest" 
							class="text-gray-darker"
							stroke="currentColor" 
							stroke-width="2.98" 
							stroke-linecap="round"
						/>
						<path 
							d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" 
							fill="none" 
							:class="campaignTotal.stats.sum >= campaignTotal.target.sum ? 'text-green' : 'text-yellow-500'"
							stroke="currentColor" 
							stroke-width="3" 
							:stroke-dasharray="`${campaignTotal.stats.sum >= campaignTotal.target.sum ? 100 : Math.round( campaignTotal.stats.sum / campaignTotal.target.sum * 100 )}, 100`" 
							stroke-linecap="round"
						/>
					</svg>
					
					<div class="absolute inset-0 flex items-center justify-center text-4xl">
						<div class="font-bold items-start flex text-white">
							{{ `${campaignTotal.stats.sum >= campaignTotal.target.sum ? 100 : Math.round( campaignTotal.stats.sum / campaignTotal.target.sum * 100 )}` }}%
						</div>
					</div>
				</div>
				<div 
					v-else-if="campaign.type == 'tasks'"
					class="w-48 rounded-full relative mx-auto">
					
					<svg viewBox="0 0 36 36">
						<path 
							d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" 
							fill="none" 
							class="text-beige-light"
							stroke="currentColor" 
							stroke-width="2.98" 
							stroke-linecap="round"
						/>
						<path 
							d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" 
							fill="none" 
							:class="campaignTotal.stats.qty >= campaignTotal.target.qty ? 'text-green' : 'text-yellow-500'"
							stroke="currentColor" 
							stroke-width="3" 
							:stroke-dasharray="`${campaignTotal.stats.qty >= campaignTotal.target.qty ? 100 : Math.round( campaignTotal.stats.qty / campaignTotal.target.qty * 100 )}, 100`" 
						/>
					</svg>
					
					<div class="absolute inset-0 flex items-center justify-center text-4xl">
						<div class="font-bold items-start flex text-black">
							{{ `${campaignTotal.stats.qty >= campaignTotal.target.qty ? 100 : Math.round( campaignTotal.stats.qty / campaignTotal.target.qty * 100 )}` }}%
						</div>
					</div>
				</div>
				
				
				<!-- <pre>{{ campaignTotal }}</pre> -->
				
				
				<h3>
					<span v-if="campaign.endAt > new Date().getTime()">
						Kampanjeperioden avsluttes {{ campaign.endAt | moment('from') }}
					</span>
					<span v-else-if="campaign.startAt > new Date().getTime()">
						Kampanjen starter {{ campaign.endAt | moment('from') }}
					</span>
					<span v-else-if="campaign.endAt < new Date().getTime()">
						Kampanjen var avsluttet {{ campaign.endAt | moment('from') }}
					</span>
				</h3>
			</div>
			
			<div class="overflow-hidden w-full bg-beige">
				<Graph
					class="mx-6"
					:option="option"
					classes="h-100"
				/>
			</div>
			
			
			<div 
				v-if="
					campaign.leaderboard && 
					campaign.leaderboard.active &&
					leaderboard
				"
				class="p-6">
				
				<Leaderboard 
					v-if="campaign.type == 'products'"
					:title="`Topp ${campaign.leaderboard.positions} salg av produkter`"
					:settings="campaign.leaderboard"
					type="products"
					:users="users"
					:leaderboard="leaderboard"
					class="flex-grow"
				/>
				<Leaderboard 
					v-else
					:title="`Topp ${campaign.leaderboard.positions} utførte oppgaver`"
					:settings="campaign.leaderboard"
					type="tasks"
					:users="users"
					:leaderboard="leaderboard"
					class="flex-grow"
				/>
			</div>
			
			
			
			<div class="p-6">
				<!-- campaignStatsItems:
				<pre>{{ campaignStatsItems }}</pre> -->
				
				<template v-if="campaign.campaignTargets.products.metric == 'individually'">
					<h2 class="my-6">Status - Individuelle produkter</h2>
					
					<div class="grid grid-cols-1 gap-6 mb-12">
						<div 
							v-for="(item, index) in campaignStatsItems.filter( item => item.target.sum)"
							:key="'item_'+index"
							class="p-4 bg-beige rounded-lg flex ">
							
							<div class="flex-grow">
								<strong>{{ item.name }}</strong>
								<div>Kampanjemål: {{ item.target.sum }} kr</div>
								<div>Totalt salg: {{ item.stats.sum }} kr</div>
							</div>
							
							<div class="w-20 flex-none relative">
								<svg viewBox="0 0 36 36">
									<path 
										d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" 
										fill="none" 
										class="text-beige-dark"
										stroke="currentColor" 
										stroke-width="2.98" 
										stroke-linecap="round"
									/>
									<path 
										d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" 
										fill="none" 
										:class="item.stats.sum >= item.target.sum ? 'text-green' : 'text-yellow-500'"
										stroke="currentColor" 
										stroke-width="3" 
										:stroke-dasharray="`${item.stats.sum >= item.target.sum ? 100 : Math.round( item.stats.sum / item.target.sum * 100 )}, 100`" 
									/>
								</svg>
								
								<div class="absolute inset-0 flex items-center justify-center text-xl">
									<div class="font-bold items-start flex text-black">
										{{ Math.round( item.stats.sum / item.target.sum * 100 ) }}%
									</div>
								</div>
							</div>
						</div>
					</div>
				</template>
				
				<template v-else-if="campaign.campaignTargets.tasks.metric == 'individually'">
					<h2 class="my-6">Status - Individuelle oppgaver</h2>
					
					<!-- <pre>{{ campaignStatsItems }}</pre> -->
					
					<div class="grid grid-cols-1 gap-6 mb-12">
						<div 
							v-for="(item, index) in campaignStatsItems.filter( item => item.target && item.target.qty)"
							:key="'item_'+index"
							class="p-4 bg-beige rounded-lg flex ">
							
							<div class="flex-grow">
								<strong>{{ item.name }}</strong>
								<div>Kampanjemål: {{ item.target.qty }}</div>
								<div>Oppgaver fullført: {{ item.stats.qty }}</div>
							</div>
							
							<div class="w-20 flex-none relative">
								<svg viewBox="0 0 36 36">
									<path 
										d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" 
										fill="none" 
										class="text-beige-dark"
										stroke="currentColor" 
										stroke-width="2.98" 
										stroke-linecap="round"
									/>
									<path 
										d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" 
										fill="none" 
										:class="item.stats.qty >= item.target.qty ? 'text-green' : 'text-yellow-500'"
										stroke="currentColor" 
										stroke-width="3" 
										:stroke-dasharray="`${item.stats.qty >= item.target.qty ? 100 : Math.round( item.stats.qty / item.target.qty * 100 )}, 100`" 
									/>
								</svg>
								
								<div class="absolute inset-0 flex items-center justify-center text-xl">
									<div class="font-bold items-start flex text-black">
										{{ Math.round( item.stats.qty / item.target.qty * 100 ) }}%
									</div>
								</div>
							</div>
						</div>
					</div>
				</template>
				
				 
				<h2 class="my-6">Alle transaksjoner i kampanjen</h2>
				
				<!-- <pre>{{ campaignTransactions }}</pre> -->
				
				<div class="grid grid-cols-1 gap-6 mb-6">
					<TransactionCard 
						v-for="t in campaignTransactions"
						:key="'departmentTransaction_'+t.transactionId"
						:transaction="t"
						type="user"
						:users="users"
						:departments="departments"
					/>
				</div>
				
			</div>
			
		</div>
		<div 
			v-else-if="!campaign.campaignTargets"
			class="h-full flex items-center justify-center">
			
			Det er ikke satt opp noen kampanjemål ennå.
		</div>
		<div 
			v-else
			class="h-full flex items-center justify-center">
			
			Fant ingen registrert aktivitet i kampanjenperioden
		</div>
		
		
		<!-- v-if="transactions && transactions.length" -->
		<div 
			class="flex-none flex p-6 space-x-4">
			
			<select 
				class="flex-grow"
				v-model="exportFormat">
				
				<option value="excel">Excel regneark</option>
				<!-- <option value="csv">CSV (Comma separated values)</option> -->
			</select>
			
			
			<button 
				type="submit"
				@click="onTransactionExport( campaignTransactions )" 
				class="button submit">
				
				Last ned
			</button>
		</div>
	</div>
</template>


<script>
import CampaignMixins from '@/mixins/CampaignMixins';
import ExportTransactionsMixins from '@/mixins/ExportTransactionsMixins';

export default {
	mixins: [
		CampaignMixins,
		ExportTransactionsMixins,
	],
	
	components: {
        'Graph': () => import('@/components/Graph.vue'),
		'Leaderboard': () => import('@/components/stats/Leaderboard.vue'),
		'TransactionCard': () => import('@/components/transactions/TransactionCard.vue'),
    },
	
	props: {
		campaignId: {
			type: String,
			required: true,
		},
		dateRangeEnumerate: {
			// type: Object || Array,
			required: true,
		}
	},
	
	computed:{
        users(){
            const users = this.$store.getters.getUsers;
			if (!users) {
				return [];
			}
			
			return users;
        },
        
		campaigns(){
			return this.$store.getters.getCampaigns;
		},
		
		campaign(){
			if ( !this.campaigns ) {
				return;
			}
			
			return this.campaigns.find( c => c.campaignId == this.campaignId);
		},
		
        departments(){
            return this.$store.getters.getDepartments;
        },
		
		department(){
			if ( !this.departments ) {
				return;
			}
			
			return this.departments.find( d => d.departmentId == this.campaign.departmentId);
		},
		
		option(){
			return {
				xAxis: {
					type: 'category',
					data: this.dateRangeEnumerate.map( date => {
						return this.$moment(date).format('D MMM');
					}),
				},
				yAxis: {
					type: 'value',
				},
				tooltip: {
					trigger: "item",
					formatter: this.campaign.type == 'products' ? 'Produktsalg totalsum<br/>{b} : {c} kr' : 'Oppgaver fullført<br/>{b} : {c}',
					// formatter: "{a} <br/>{b} : {c} ({d}%)"
				},
				series: [
					{
						type: 'bar',
						data: this.campaignStatsDateRangeEnumerate.map( date => {
							if ( this.campaign.type == 'products' ) {
								return date.sum;
							}
							else if ( this.campaign.type == 'tasks' ) {
								return date.qty;
							}
						}),
					},
				],
				color: [
					'#2ebd59',
				]
			}
		},
		
		leaderboard(){
			let leaderboard = {};
			
			this.campaignTransactions.forEach( transaction => {
				if ( !leaderboard[ transaction.username ] ) {
					leaderboard[ transaction.username ] = {
						products: {
							qty: 0,
							sum: 0,
						},
						tasks: {
							qty: 0,
						}
					};
				}
				
				
				
				if ( this.campaign.type == 'products') {
					transaction.products.forEach( item => {
						leaderboard[ transaction.username ].products.qty += Number( item.qty);
						leaderboard[ transaction.username ].products.sum += Number( item.qty) * Number( item.value);
					});
				}
				else if ( this.campaign.type == 'tasks') {
					transaction.tasks.forEach( item => {
						leaderboard[ transaction.username ].tasks.qty += Number( item.qty);
					});
				}
			});
			
			return leaderboard;
		}
    },
	
	data() {
		return {
			loading: null,
			transactions: null,
			exportFormat: 'excel',
		}
	},
	
	methods: {
        async onFetchTransactions(){
            try {
                let params = {
					departmentId: this.campaign.departmentId,
                    from: this.campaign.startAt,
                    to: this.campaign.endAt,
                };
                
                this.transactions = await this.$store.dispatch('listTransactions', params );
            } 
            catch (e) {
                // this.$notify({ 
                //     type: 'error', 
                //     text: e.message || 'Kunne ikke hente transaksjoner'
                // });
                
                console.error(e);
            } 
        },
	},
	
	async mounted(){
		if ( !this.campaign.campaignTargets ) {
			return;
		}
		
		try {
			this.loading = 'fetching';
			
			await Promise.allSettled([
				this.onFetchTransactions(),
				this.$store.dispatch('listUsers'),
			]);
		} 
		catch (e) {
			this.$notify({ 
				type: 'error', 
				text: e.message || 'Kunne ikke hente transaksjoner'
			});
			
			console.error(e);
		} 
		finally {
			this.loading = null;
		}
	},
}
</script>