export default {
	computed: {
		campaignTransactions(){
			if ( !this.transactions ) {
				return null
			}
			
			return this.transactions
				.filter( t => {
					if ( t.createdAt >= this.campaign.startAt && t.createdAt <= this.campaign.endAt ) {
						return true
					}
					return false
				})
				.filter( transaction => {
					if ( 
						this.campaign.type &&
						this.campaign.type == 'products' &&
						this.campaign.departmentId == transaction.departmentId &&
						transaction.products &&
						transaction.products.length ) {
						
						return true;
					}
					else if ( 
						this.campaign.type &&
						this.campaign.type == 'tasks' &&
						this.campaign.departmentId == transaction.departmentId &&
						transaction.tasks &&
						transaction.tasks.length ) {

						return true
					}
				})
				.map( transaction => {
					if ( this.campaign.type == 'products' ) {
						if ( this.campaign.campaignTargets.products.mode == 'some' ) {
							// only return the individual products that are included in this campaign!
							transaction.products = transaction.products.filter( transactionProduct => {
								return this.campaign.products.find( p => p.productId == transactionProduct.productId);
							});
						}
					}
					else if ( this.campaign.type == 'tasks' ) {
						if ( this.campaign.campaignTargets.tasks.mode == 'some' ) {
							// only return the individual tasks that are included in this campaign!
							transaction.tasks = transaction.tasks.filter( transactionTask => {
								return this.campaign.tasks.find( t => t.taskId == transactionTask.taskId);
							});
						}
					}
					
					return transaction;
				})
				.filter( transaction => {
					if ( 
						this.campaign.type &&
						this.campaign.type == 'products' &&
						transaction.products &&
						transaction.products.length ) {
						
						return true;
					}
					else if ( 
						this.campaign.type &&
						this.campaign.type == 'tasks' &&
						transaction.tasks &&
						transaction.tasks.length ) {

						return true
					}
				})
		},
		
		campaignStatsItems(){
			if ( this.campaign.type == 'products') {
				
				// if ( 
				// 	!this.campaign.products || 
				// 	!this.campaign.products.length ) {
				// 	return null
				// }
				
				const products = this.department.products
					.filter( product => {
						if ( this.campaign.campaignTargets.products.mode == 'all') {
							return true
						}
						else if ( 
							this.campaign.campaignTargets.products.mode == 'some' &&
							this.campaign.products.find( p => p.productId == product.productId) ) {
							
							return true
						}
						
						return false
					})
					.map( product => {
						let target = {
							qty: 0,
							sum: 0,
						};
						
						if ( this.campaign.campaignTargets.products.metric == 'individually' ) {
							target = this.campaign.products.find( p => p.productId == product.productId).target;
						}
						
						let stats = {
							qty: 0,
							sum: 0,
						};
						
						if ( 
							this.campaignTransactions && 
							this.campaignTransactions.length ) {
							
							this.campaignTransactions.forEach( transaction => {
								transaction.products.forEach( t => {
									if (t.productId == product.productId) {
										stats.qty += Number(t.qty);
										stats.sum += Number(t.qty) * Number(t.value);
									}
								});
							});
						}
						
						return {
							...product,
							target,
							stats,
						}
					});
					
				return products;
			}
			else if ( this.campaign.type == 'tasks') {
				const tasks = this.department.tasks
					.filter( task => {
						if ( this.campaign.campaignTargets.tasks.mode == 'all') {
							return true
						}
						else if ( 
							this.campaign.campaignTargets.tasks.mode == 'some' &&
							this.campaign.tasks.find( t => t.taskId == task.taskId) ) {
							
							return true
						}
						
						return false
					})
					.map( task => {
						let target = {
							qty: 0,
						};
						
						if ( this.campaign.campaignTargets.tasks.metric == 'individually' ) {
							target = this.campaign.tasks.find( t => t.taskId == task.taskId).target;
						}
						
						let stats = {
							qty: 0,
						};
						
						if ( 
							this.campaignTransactions && 
							this.campaignTransactions.length ) {
							
							this.campaignTransactions.forEach( transaction => {
								transaction.tasks.forEach( t => {
									if (t.taskId == task.taskId) {
										stats.qty += Number(t.qty);
									}
								});
							});
						}
						
						return {
							...task,
							target,
							stats,
						}
					});
					
				return tasks;
			}
			
			return null;
		},
		
		campaignTotal() {
			// calculates campaign completion in percent!
			let targetQty = 0;
			let targetSum = 0;
			let statsQty = 0;
			let statsSum = 0;
				
			if ( 
				this.campaignStatsItems && 
				this.campaignStatsItems.length ) {
				
				if ( 
					this.campaign.campaignTargets.products.metric == 'individually' ||
					this.campaign.campaignTargets.tasks.metric == 'individually' ) {
					
					targetQty = this.campaignStatsItems.length * 100; // multiply all individual items as 100% on each
					targetSum = this.campaignStatsItems.length * 100; // multiply all individual items as 100% on each
				}
				else {
					targetQty = 100; // %
					targetSum = 100; // %
				}
				
				this.campaignStatsItems.forEach( item => {
					let itemPercentQty = 0;
					let itemPercentSum = 0;
					
					if ( item?.stats?.qty || item?.stats?.sum) {
						if ( this.campaign.type == 'products' ) {
							if ( this.campaign.campaignTargets.products.metric == 'individually') {
								itemPercentQty = item.stats.qty >= item.target.qty ? 100 : Math.round( item.stats.qty / item.target.qty * 100 );
								itemPercentSum = item.stats.sum >= item.target.sum ? 100 : Math.round( item.stats.sum / item.target.sum * 100 );
							}
							else {
								itemPercentQty = item.stats.qty >= this.campaign.campaignTargets.products.qty ? 100 : Math.round( item.stats.qty / this.campaign.campaignTargets.products.qty * 100 );
								itemPercentSum = item.stats.sum >= this.campaign.campaignTargets.products.sum ? 100 : Math.round( item.stats.sum / this.campaign.campaignTargets.products.sum * 100 );
							}
						}
						else if ( this.campaign.type == 'tasks' ) {
							if ( this.campaign.campaignTargets.tasks.metric == 'individually' ) {
								itemPercentQty = item.stats.qty >= item.target.qty ? 100 : Math.round( item.stats.qty / item.target.qty * 100 );
							}
							else {
								itemPercentQty = item.stats.qty >= this.campaign.campaignTargets.tasks.qty ? 100 : Math.round( item.stats.qty / this.campaign.campaignTargets.tasks.qty * 100 );
							}
						}
						
						statsQty += itemPercentQty;
						statsSum += itemPercentSum;
					}
				});
			}
			
			return {
				target: {
					qty: targetQty,
					sum: targetSum,
				},
				stats: {
					qty: statsQty,
					sum: statsSum,
				}
			};
		},
		
		campaignStatsDateRangeEnumerate() {
			if ( !this.dateRangeEnumerate ){
				return null;
			}
			
			return this.dateRangeEnumerate.map( date => {
				let dateResponse = {
					date,
					qty: 0,
					sum: 0,
				};
				
				this.campaignTransactions
					.filter( transaction => {
						let start = this.$moment(date).valueOf();
						let end = this.$moment(date).endOf('day').valueOf();
						
						if (transaction.createdAt > start && transaction.createdAt < end) {
							return true
						}
					})
					.forEach( transaction => {
						if ( this.campaign.type == 'products') {
							transaction.products.forEach( item => {
								dateResponse.qty += Number( item.qty);
								dateResponse.sum += Number( item.qty) * Number( item.value);
							});
						}
						else if ( this.campaign.type == 'tasks') {
							transaction.tasks.forEach( item => {
								dateResponse.qty += Number( item.qty);
							});
						}
					});
				
				return dateResponse;
			});
		},
	},
	
	data() {
		return {
		   
		}
	},
	
}
